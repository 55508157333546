import { http } from '@/http/axios.js'
// list
export function getListAPI(params) {
  return http({
    url: '/company/guideWebsite/datav/getGuideDayPvList',
    method: 'get',
    params
  })
}
// detail
export function getGuideDayPvAPI() {
  return http({
    url: '/company/guideWebsite/datav/guideDayPv',
    method: 'get'
  })
}
