<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="chart">
      <LineChartMult title="近15日访问趋势" :list_x="list_x" :list_y1="list_y1" :list_y2="list_y2" :legend="['访问量', 'pv']"></LineChartMult>
    </div>

    <el-divider></el-divider>
    <admin-title title="详情"></admin-title>
    <el-form ref="form" inline :model="searchData" label-width="">
      <el-form-item style="margin-right: 30px" label="日期">
        <el-date-picker v-model="searchData.day" value-format="yyyy-MM-dd" type="date" size="small" placeholder="选择日期"> </el-date-picker>
      </el-form-item>
      <el-form-item label="ip">
        <el-input v-model="searchData.ip" clearable placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="getList">搜索</el-button>
        <el-button type="" size="small" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="page_size"
    />
  </div>
</template>

<script>
import { getGuideDayPvAPI, getListAPI } from './api'
import LineChartMult from './charts/line-chart-mult.vue'
const columns = [
  {
    label: 'ip',
    prop: 'ip',
    minWidth: '160',
    customRender(h, row) {
      return <p>{row['ip']}</p>
    }
  },
  {
    label: '日期',
    prop: 'day',
    minWidth: '160',
    customRender(h, row) {
      return <p>{row['day']}</p>
    }
  },

  {
    label: 'useragent',
    prop: 'useragent',
    minWidth: '350',
    customRender(h, row) {
      return (
        <p class="two" style="margin:5px 0" title={row['useragent']}>
          {row['useragent']}
        </p>
      )
    }
  }
]
export default {
  name: 'List',
  components: { LineChartMult },
  data() {
    return {
      columns,
      currentPage: 1,
      page_size: 10,
      total: 0,
      list: [],

      searchData: {
        day: '',
        ip: ''
      },
      guidePvData: []
    }
  },
  provide() {
    return {
      context: this
    }
  },
  computed: {
    list_x() {
      return this.guidePvData.map((item) => {
        return item.label
      })
    },
    // value
    list_y1() {
      return this.guidePvData.map((item) => {
        return item.value
      })
    },
    // total
    list_y2() {
      return this.guidePvData.map((item) => {
        return item.total
      })
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    page_size() {
      this.getList()
    }
  },
  created() {},
  mounted() {
    this.getCurrentDate()

    this.getGuideDayPv()
  },
  methods: {
    async getList() {
      let pageParams = { page: this.currentPage, page_size: this.page_size }
      let params = Object.assign(pageParams, this.searchData)
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    getGuideDayPv() {
      getGuideDayPvAPI().then((res) => {
        this.guidePvData = res
      })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    getCurrentDate() {
      const date = new Date()
      const y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      const str = y + '-' + m + '-' + d
      this.searchData.day = str
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;

  .chart {
    height: 400px;
  }

  .el-divider--horizontal {
    margin: 0 0 20px;
  }
  .two {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .useragent .el-tooltip__popper {
    padding: 8px 10px;
  }
}
</style>
