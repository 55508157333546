<template>
  <div ref="line" class="line"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'LineChart',
  props: {
    list_x: {
      type: Array,
      default: () => []
    },
    list_y1: {
      type: Array,
      default: () => []
    },
    list_y2: {
      type: Array,
      default: () => []
    },
    legend: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      myChart: null
    }
  },
  watch: {
    list_x: {
      handler(newV, oldV) {
        this.init()
      }
    },
    list_y1: {
      handler(newV, oldV) {
        this.init()
      }
    },
    list_y2: {
      handler(newV, oldV) {
        this.init()
      }
    }
  },
  computed: {},
  mounted() {},

  methods: {
    init() {
      this.myChart = echarts.init(this.$refs.line)
      this.myChart.setOption({
        title: [
          {
            text: this.title,
            left: 'center',
            top: '35px',
            textStyle: { color: '#1E6BEB', fontSize: '16px' }
          }
          // {
          //   subtext: 'xxx充电站',
          //   left: 'right',
          //   top: '25px',
          //   subtextStyle: { color: '#1E6BEB', fontSize: '14px' }
          // }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          top: '25%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.list_x,
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: { interval: 0, rotate: 30 }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: { show: true },
            axisTick: { show: true }
          }
        ],
        legend: {
          data: this.legend
        },
        series: [
          {
            name: this.legend[0],
            type: 'line',
            data: this.list_y1,
            smooth: true
          },
          {
            name: this.legend[1],
            type: 'line',
            data: this.list_y2,
            smooth: true
          }
        ]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.line {
  width: 100%;
  height: 100%;
}
</style>
